/**
 * Everything amplitude tracking!
 */

import React, { useEffect } from 'react';
import { tracking, useTracking } from '@podiumhq/tracking';
import isQABot from './isQaBot';

/**
 * The aggregated events schema for Amplitude events.
 */
const EVENTS = {
  reviewsIframe: {
    opened: true,
  },
};

function setup() {
  const enabled = process.env.NODE_ENV === 'production' && !isQABot();

  tracking.init({
    enabled,
    events: EVENTS,
    debug: false,
    amplitudeToken: process.env.MERMAID_MAN_AMPLITUDE_TOKEN,
    amplitudeConfig: {
      plan: {
        version: '',
      },
    },
  });
}

/**
 * Used to wrap your sub-app. Enabling you to track Amplitude events.
 * @param {Object} props Props for the AmplitudeProvider component.
 */
function AmplitudeProvider({ children }) {
  const { Track } = useTracking();

  useEffect(() => {
    setup();
  }, []);

  return <Track>{children}</Track>;
}

export default AmplitudeProvider;
