import React from 'react';
import moment from 'moment';
import {
  Author,
  Body,
  Date,
  Recommendation,
  ReviewHeader,
  ReviewWrapper,
  SiteLogo,
  Star,
  Stars,
} from './ReviewStyledComponents';
import PropTypes from 'prop-types';
import { siteIcons } from '../../constants/siteIcons';

const MAX_REVIEW_RATING = 5;
const RECOMMENDATION_TEXT = 'Recommends';
const STAR_ON = '//assets.podium.com/icons/star-on.png';
const STAR_OFF = '//assets.podium.com/icons/star-off.png';

const renderStarsOrRecommendation = (rating, isRecommendation) => {
  if (isRecommendation)
    return <Recommendation>{RECOMMENDATION_TEXT}</Recommendation>;

  return (
    <Stars data-testid="review-stars">
      {Array.from({ length: MAX_REVIEW_RATING }, (_, index) => {
        return (
          <Star
            src={index < Math.round(rating) ? STAR_ON : STAR_OFF}
            alt="star"
            key={`star${index}`}
          />
        );
      })}
    </Stars>
  );
};

const Review = (props) => {
  const {
    review: {
      author,
      is_recommendation: isRecommendation,
      publish_date: publishDate,
      review_body: reviewBody,
      site_name: siteName,
      rating,
    },
  } = props;

  const formattedDate = moment(publishDate).format('DD MMM YYYY');

  return (
    <ReviewWrapper>
      <SiteLogo data-testid="review-site-logo">
        <img src={siteIcons[siteName]} alt={siteName} />
      </SiteLogo>
      <ReviewHeader>
        <Author>{author}</Author>
        {renderStarsOrRecommendation(rating, isRecommendation)}
      </ReviewHeader>
      <Date>{formattedDate}</Date>
      <Body>{reviewBody}</Body>
    </ReviewWrapper>
  );
};

Review.defaultProps = {
  review: {},
};

Review.propTypes = {
  review: PropTypes.shape({
    author: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    is_recommendation: PropTypes.bool,
    publish_date: PropTypes.string,
    review_body: PropTypes.string,
    site_name: PropTypes.string,
    rating: PropTypes.number,
  }),
};

export default Review;
