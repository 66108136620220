export const siteIcons = {
  angieslist: 'https://assets.podium.com/icons/angieslist.svg',
  avvo: 'https://assets.podium.com/icons/avvo.svg',
  bbb: 'https://assets.podium.com/icons/bbb.svg',
  bestcompany: 'https://assets.podium.com/icons/bestcompany.svg',
  booking: 'https://assets.podium.com/icons/booking.svg',
  carfax: 'https://assets.podium.com/icons/carfax.svg',
  cars: 'https://assets.podium.com/icons/cars.svg',
  cargurus: 'https://assets.podium.com/icons/cargurus.svg',
  citysearch: 'https://assets.podium.com/icons/citysearch.svg',
  consumeraffairs: 'https://assets.podium.com/icons/consumeraffairs.svg',
  dealerrater: 'https://assets.podium.com/icons/dealerrater.svg',
  doctor: 'https://assets.podium.com/icons/doctor.svg',
  edmunds: 'https://assets.podium.com/icons/edmunds.svg',
  expedia: 'https://assets.podium.com/icons/expedia.svg',
  facebook: 'https://assets.podium.com/icons/facebook.svg',
  g2crowd: 'https://assets.podium.com/icons/g2crowd.svg',
  google: 'https://assets.podium.com/icons/google.svg',
  healthgrades: 'https://assets.podium.com/icons/healthgrades.svg',
  homeadvisor: 'https://assets.podium.com/icons/homeadvisor.svg',
  merchantcircle: 'https://assets.podium.com/icons/merchantcircle.svg',
  productreview: 'https://assets.podium.com/icons/productreview.svg',
  solarreviews: 'https://assets.podium.com/icons/solarreviews.svg',
  tripadvisor: 'https://assets.podium.com/icons/tripadvisor.svg',
  vitals: 'https://assets.podium.com/icons/vitals.svg',
  yellowpages: 'https://assets.podium.com/icons/yellowpages.svg',
  yelp: 'https://assets.podium.com/icons/yelp.svg',
  zillow: 'https://assets.podium.com/icons/zillow.svg',
};
