import React, { useEffect, useState } from 'react';
import { useTracking } from '@podiumhq/tracking';
import { ReviewsList } from './components';
import { getUrlParams } from './utils/getUrlParams';
import AmplitudeProvider from './lib/AmplitudeProvider';

const WEEZING_API_HOST = process.env.REACT_APP_WEEZING_API_HOST;
const KUMETE_API_HOST =
  process.env.REACT_APP_KUMETE_API_HOST || 'https://api.podium.com';

// Returns the correct API endpoint to fetch reviews.
// If a `locationSlug` is present it will make a request to Weezing (proxies to Roman Legion).
// If a `locationUid` is present it will make a request to Kumete (proxies to Snowden).
// If neither are present it will return null.
const getReviewsUrl = () => {
  const { locationSlug, locationUid, count } = getUrlParams();
  let reviewsUrl = '';

  if (locationSlug) {
    reviewsUrl = `${WEEZING_API_HOST}/api/v1/reviews/iframe/${locationSlug}`;
  }

  if (locationUid) {
    reviewsUrl = `${KUMETE_API_HOST}/iframe/api/reviews/${locationUid}`;
  }

  if (reviewsUrl === '') return { reviewsUrl };

  if (count) reviewsUrl += `?count=${count}`;

  return { count, locationSlug, locationUid, reviewsUrl };
};

const getHeaders = () => {
  const { locationSlug } = getUrlParams();
  const weezingHeaders = { 'x-client-application': 'mermaid-man' };

  return locationSlug ? weezingHeaders : {};
};

const fetchReviews = async (setReviews, trackEvent) => {
  const { count, locationSlug, locationUid, reviewsUrl } = getReviewsUrl();
  if (!reviewsUrl) return false; // don't make request if no location identifier is present

  trackEvent('reviewsIframe.opened', {
    count,
    locationSlug,
    locationUid,
    reviewsUrl,
  });

  const response = await fetch(reviewsUrl, { headers: getHeaders() });
  const { reviews } = await response.json();
  setReviews(reviews);
};

const App = () => {
  const [reviews, setReviews] = useState([]);
  const { trackEvent } = useTracking();

  useEffect(() => {
    fetchReviews(setReviews, trackEvent);
  }, [trackEvent]);

  return (
    <AmplitudeProvider>
      <div style={{ maxWidth: '518px' }}>
        <ReviewsList reviews={reviews} />
      </div>
    </AmplitudeProvider>
  );
};

export default App;
