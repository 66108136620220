import styled from 'styled-components';

export const ReviewListFooter = styled.div`
  margin: 10px auto;
  width: 100%;

  img {
    display: block;
    margin: auto;
    height: 23px;
  }
`;

export const ReviewListHeader = styled.div`
  border-bottom: 1px solid #ddd;
  padding: 10px;

  h3 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    margin: 0;
  }
`;

export const ReviewListWrapper = styled.div`
  max-width: 518px;
  min-width: 325px;
  border: 1px solid #ddd;
  border-radius: 8px;
`;
