import styled from 'styled-components';

export const Author = styled.div`
  margin-top: 0;
  margin: 0 5px 0 0;
  font-size: 14px;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Body = styled.div`
  margin: 3px 0 3px 3px;
  font-size: 13px;
  font-weight: normal;
`;

export const Date = styled.span`
  position: absolute;
  right: 12px;
  top: 10px;
  margin: 0;
  margin-left: 40px;
  font-size: 13px;
  font-weight: normal;
  color: #666;
`;

export const Recommendation = styled.span`
  color: #666;
  font-size: 12px;
  font-weight: normal;
`;

export const ReviewHeader = styled.div`
  min-width: 232px;
  padding-right: 72px;
`;

export const ReviewWrapper = styled.div`
  position: relative;
  padding: 10px;
  border-bottom: 1px solid #ddd;

  &:last-child {
    margin-bottom: 0px;
  }
`;

export const SiteLogo = styled.div`
  float: left;

  img {
    margin-right: 10px;
    border-radius: 50%;
    width: 30px;
  }
`;

export const Star = styled.img`
  margin-right: 4px;
`;

export const Stars = styled.div`
  position: relative;
  top: 2px;
  margin-left: 40px;

  img {
    white-space: nowrap;
  }
`;
