import React from 'react';
import {
  ReviewListFooter,
  ReviewListHeader,
  ReviewListWrapper,
} from './ReviewsListStyledComponents';
import { Review } from '../Review';
import PropTypes from 'prop-types';

const FOOTER_LOGO_SRC = '//assets.podium.com/logos/Podium_Horizontal.png';
const FOOTER_LOGO_URL = '//podium.com';
const HEADER_TEXT = 'Recent Reviews';

const renderHeader = () => (
  <ReviewListHeader>
    <h3>{HEADER_TEXT}</h3>
  </ReviewListHeader>
);

const renderFooter = () => (
  <ReviewListFooter data-testid="review-list-footer">
    <a href={FOOTER_LOGO_URL} rel="noopener noreferrer" target="_blank">
      <img src={FOOTER_LOGO_SRC} alt="Podium Logo" />
    </a>
  </ReviewListFooter>
);

const renderReviews = (reviews) =>
  reviews.map((review) => <Review key={review.id} review={review} />);

const ReviewsList = ({ reviews }) => (
  <ReviewListWrapper>
    {renderHeader()}
    {renderReviews(reviews)}
    {renderFooter()}
  </ReviewListWrapper>
);

ReviewsList.defaultProps = {
  reviews: [],
};

ReviewsList.propTypes = {
  reviews: PropTypes.arrayOf(
    PropTypes.shape({
      author: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      is_recommendation: PropTypes.bool,
      publish_date: PropTypes.string,
      review_body: PropTypes.string,
      site_name: PropTypes.string,
      rating: PropTypes.number,
    })
  ),
};

export default ReviewsList;
